import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/react";
import Hamburger from "hamburger-react";

export default function Dropdown() {
  const handleClick = (url: string) => {
    window.location.href = url;
  };
  return (
    <Menu>
      {({ open }) => (
        <>
          <MenuButton className="grid place-content-center rounded-md hamburger_bg  h-9 w-9  text-gray-900  data-[hover]:bg-gray-700 data-[open]:bg-gray-700 data-[focus]:outline-1 data-[focus]:outline-white">
            <Hamburger toggled={open} size={18} hideOutline={true} />
          </MenuButton>
          {open && (
            <MenuItems
              transition
              anchor="bottom end"
              className="w-52 origin-top-right bg-[#052728] rounded-xl mt-2 border border-white/5  p-1 text-sm/6 text-white transition duration-100 ease-out [--anchor-gap:var(--spacing-1)] focus:outline-none data-[closed]:scale-95 data-[closed]:opacity-0 data-[closed]:hidden"
            >
              <MenuItem>
                <button
                  onClick={() => handleClick("https://raffle.bigfootpepe.com/")}
                  className="group flex w-full items-center gap-2 rounded-lg py-1.5 px-3 data-[focus]:bg-white/10"
                >
                  Raffle
                </button>
              </MenuItem>
              <MenuItem>
                <button className="group flex w-full items-center gap-2 rounded-lg py-1.5 px-3 data-[focus]:bg-white/10">
                  Comics
                </button>
              </MenuItem>
              <div className="h-px my-1 bg-white/5" />
              <MenuItem>
                <button
                  onClick={() => handleClick("https://bigfootpepe.com/")}
                  className="group flex w-full items-center gap-2 rounded-lg py-1.5 px-3 data-[focus]:bg-white/10"
                >
                  Buy Now
                </button>
              </MenuItem>
              <MenuItem>
                <button
                  onClick={() => handleClick("https://bigfootpepe.com/")}
                  className="group flex w-full items-center gap-2 rounded-lg py-1.5 px-3 data-[focus]:bg-white/10"
                >
                  How to buy
                </button>
              </MenuItem>
            </MenuItems>
          )}
        </>
      )}
    </Menu>
  );
}
