import ReactDOM from "react-dom/client";
import Providers from "./components/Providers";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { Helmet } from "react-helmet";

import "@rainbow-me/rainbowkit/styles.css";
import "react-toastify/dist/ReactToastify.css";
import "./styles/fonts.css";
import "./styles/reset.css";
import "./styles/index.css";
import "./styles/sasquatch.css";

import { Bridge } from "@layerzerolabs/x-trader-joe-bridge";
import Layout from "./components/Layout";
import ErrorPage from "./components/ErrorPage";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const router = createBrowserRouter([
  {
    path: "/",

    element: (
      <div className="mui-box-root">
        <Bridge />
      </div>
    ),
  },
  {
    path: "raffle",
    element: <div>Hello world Raffle!</div>,
    errorElement: <ErrorPage />,
  },
  {
    path: "lore",
    element: <div>Hello world Lore!</div>,
    errorElement: <ErrorPage />,
  },
]);

root.render(
  <>
    <Helmet>
      <meta charSet="utf-8" />
      <title>BigFoot Bridge</title>
    </Helmet>
    <Providers>
      <Layout>
        <RouterProvider router={router} />
      </Layout>
    </Providers>
  </>
);
