import { ConnectButton } from "@rainbow-me/rainbowkit";
import logo from "../assets/logo/logo_hand.webp";
import Dropdown from "./Dropdown";

const Navbar = () => {
  return (
    <header className="">
      <div className="max-w-screen-xl px-4 mx-auto sm:px-6 lg:px-2">
        <div className="flex items-center justify-between h-16">
          <div className="md:flex md:items-center md:gap-12">
            <a className="block text-teal-600 dark:text-teal-600" href="#">
              <span className="sr-only">Home</span>
              <img
                src={logo}
                alt="logo"
                className="object-scale-down w-20 h-20 pt-2"
              />
            </a>
          </div>

          <div className="hidden lg:block">
            <nav aria-label="Global">
              <ul className="flex items-center gap-6 text-lg font-graffiti">
                <li>
                  <a
                    className="text-gray-500 transition hover:text-gray-500/75 dark:text-white dark:hover:text-white/75"
                    href="https://apps.bigfootpepe.com/"
                  >
                    Raffle
                  </a>
                </li>

                <li>
                  <a
                    className="text-gray-500 transition hover:text-gray-500/75 dark:text-white dark:hover:text-white/75"
                    href="https://apps.bigfootpepe.com/comics"
                  >
                    Comics
                  </a>
                </li>
                <li>
                  <a
                    className="text-gray-500 transition hover:text-gray-500/75 dark:text-white dark:hover:text-white/75"
                    href="https://bigfootpepe.com/"
                  >
                    Buy Now
                  </a>
                </li>

                <li>
                  <a
                    className="text-gray-500 transition hover:text-gray-500/75 dark:text-white dark:hover:text-white/75"
                    href="https://bigfootpepe.com/"
                  >
                    About
                  </a>
                </li>
              </ul>
            </nav>
          </div>

          <div className="flex items-center gap-4">
            <ConnectButton />
            <div className="block h-9 w-9 lg:hidden">
              {" "}
              <Dropdown />
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Navbar;
