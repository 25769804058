import {
  ThemeProvider,
  bootstrap,
  createWagmiProvider,
  themeLight,
} from "@layerzerolabs/x-trader-joe-bridge";

import { Analytics } from "@vercel/analytics/react";
import { SpeedInsights } from "@vercel/speed-insights/react";
// import { testnet } from "../config/testnet";
import { mainnet } from "../config/mainnet";
import { wagmi } from "../config/wagmi";
import { WagmiConfig } from "wagmi";
import {
  ConnectButton,
  RainbowKitProvider,
  darkTheme,
} from "@rainbow-me/rainbowkit";
import "@rainbow-me/rainbowkit/styles.css";
import "react-toastify/dist/ReactToastify.css";
import React from "react";

const Providers: React.FC<React.PropsWithChildren> = ({ children }) => {
  // boostrap the bridge

  bootstrap(mainnet, {
    evm: wagmi.wagmiAdapter,
  });

  const EthereumAdapterProvider = createWagmiProvider(
    wagmi
  ) as React.FC<React.PropsWithChildren>;

  return (
    <WagmiConfig client={wagmi.wagmiClient as any}>
      <RainbowKitProvider
        modalSize="compact"
        chains={wagmi.wagmiChains}
        theme={darkTheme({
          fontStack: "system",
        })}
      >
        <Analytics />
        <SpeedInsights />

        <EthereumAdapterProvider>
          <ThemeProvider theme={themeLight}>{children}</ThemeProvider>
        </EthereumAdapterProvider>
      </RainbowKitProvider>
    </WagmiConfig>
  );
};

export default Providers;
