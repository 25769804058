import { ChainId } from "@layerzerolabs/lz-sdk";
import { Token } from "@layerzerolabs/x-trader-joe-bridge";

const tokens = [
  new Token(
    ChainId.BSC,
    "0x5A0536fBcFc9375C25Bfa1dDED5b30Fd927de8c7",
    18,
    "BFT"
  ),
  new Token(
    ChainId.ARBITRUM,
    "0x5A0536fBcFc9375C25Bfa1dDED5b30Fd927de8c7",
    18,
    "BFT"
  ),
  new Token(
    ChainId.ETHEREUM,
    "0x5A0536fBcFc9375C25Bfa1dDED5b30Fd927de8c7",
    18,
    "BFT"
  ),
];

const proxy = [
  {
    chainId: ChainId.ETHEREUM,
    address: "0x39d8Cb6cdF125190210611160C0C74905f85d133",
  },
];

export const mainnet = { tokens, proxy };
